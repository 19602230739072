import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SearchMarketService {

  constructor(private http: HttpBaseService) { }

  getFirmSearchMarkets(firmId: string, offset?: any, limit?: any) {
    offset = offset ? '?offset=' + offset : '?offset=0';
    limit = limit ? '&limit=' + limit : '&limit=0';
    return this.http.get('searchMarkets/firm/'+firmId + offset + limit);
  }

  getAllSearchMarkets() {
    return this.http.get("searchMarkets").subscribe((response) => {
      let indexFound;
      let found = response.data.find((market: any, index: number) => {
          if( market.name === 'Remote'){
            indexFound = index;
            return true;
          }
          return false;
      });
      response.data.splice(indexFound,1);
      response.data.splice(0,0,found);
      return response;
    });
  }

  createSearchMarket(searchMarket: any) {
    return this.http.post("searchMarkets", searchMarket);
  }

  updateSearchMarket(id: string, searchMarket: any) {
    return this.http.put("searchMarkets/" + id, searchMarket);
  }

  deleteSearchMarket(id: string) {
    return this.http.delete("searchMarkets/" + id);
  }

  getAllFirmSearchMarket(firmId: string) {
    return this.http.get('searchMarkets/firm/'+firmId);
  }

  getSMAssociations(id: string): Promise<any> {
    return lastValueFrom(this.http.get('searchMarkets/associations/' + id));
  }
}
