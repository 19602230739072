import { Injectable } from '@angular/core';
import { constants } from '../constants/constants';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  detectIfMobileBrowser () {
    let check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
            check = true;
        }
    })(navigator.userAgent || navigator.vendor);
    return check;
  }
  
  detectSafariBrowser () {
        let ua = window.navigator.userAgent;
        let hasSafariInUa = !!ua.match(/Safari/i);
        let noOtherBrowsersInUa = !ua.match(/Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox/i);
        let result = false;

        // if(window.safari !== undefined){ //detecting Safari in Desktop Browsers
        //     result = true;
        // } else { // detecting Safari in other platforms
            result = hasSafariInUa && noOtherBrowsersInUa;
        // }
        return result;
  }

  getRole (role: string) {
    if (role === constants.userRoles.projectOwner || role === constants.userRoles.projectManager) {
      role = 'Project Manager';
    } else if (role === constants.userRoles.firmManager) {
      role = 'Firm Manager';
    }
    return role;
  }


  isEmail(emailAddress: string): boolean{
    const regex = constants.emailRegex;
    return regex.test(emailAddress);
  }

  isValidUrl(url: string): boolean{
    const regex = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    return regex.test(url);
  }

  leftPaddingTime(str: string): string{
    const pad = '00';
    return str ? pad.substring(0, pad.length - str.toString().length) + str.toString() : pad;
  }

  checkIfDateIsLessThanCurrentDate(date: any) {

    let dateString = moment(date).utc().format("MM/DD/YYYY");
    date = moment(dateString, "MM/DD/YYYY");

    let currentDateString = moment(new Date()).utc().format("MM/DD/YYYY");
    let currentDate = moment(currentDateString, "MM/DD/YYYY");

    return date < currentDate;
  }

  checkIfDateTimeLessThanCurrentDateTime(date: any) {
    let projectDateString = moment(date).utc().format("MM/DD/YYYY HH:mm");
    date = moment(projectDateString, "MM/DD/YYYY HH:mm");

    let currentDateString = moment(new Date()).utc().format("MM/DD/YYYY HH:mm");
    let currentDate = moment(currentDateString, "MM/DD/YYYY HH:mm");

    return date < currentDate;
  }

  checkIfDatesAreEqual(firstDate: string, secondDate: string) {
    let firstDateString = moment(firstDate).utc().format("MM/DD/YYYY HH:mm");
    let secondDateString = moment(secondDate).utc().format("MM/DD/YYYY HH:mm");
    return firstDateString !== secondDateString;
  }

  isDateSameOrLessThanAnotherDate(date1: string, date2: string) {
    return moment.utc(date1, "MM/DD/YYYY").isSameOrBefore(moment.utc(date2));
  }

  getRoleDurations(){
    let list = [ 'Single Day or Shift', 'A Few Days', '1 Week', '2 Weeks', '3 Weeks', '1 Month', '1-2 Months', '2 Months', '3+ Months', 'Permanent/Full-Time'];
    return list.map(option => ({ label: option, value: option }));
  }

  getRoleWeeklyExpectedHours(){
    let list = ['Part time - less than 40 hours', 'Full Time - 40 hours', 'Full Time - Extra Optional', 'Full Time plus - 40 - 60 hours', 'Heavy Hours —> 60 hours'];
    return list.map(option => ({ label: option, value: option }));
  }

  utcDateObject(date: any) {
    const utcDate = date ? moment(date).utc() : moment().utc();
    return moment([
        utcDate.year(),
        utcDate.month(),
        utcDate.date(),
        utcDate.hour(),
        utcDate.minute()
    ]).toDate();
  }

  formatPhoneNo(num:string) {
    let formattedNumber = '';
    if (num) {
        formattedNumber = '(' + num.slice(0, 3) + ') ' + num.slice(3, 6) + '-' + num.slice(6, 10);
    }
    return formattedNumber;
  }

  isGreaterThanAllowedSize(file: any): boolean{
    return (file.size > constants.documentUploadSizeInMbs * 1000000);
  }

  bytesToMb(bytes: number): string {
    return (bytes / (1024*1024)).toFixed(2);
  }

  formatTime(time: { hour: number, min: number, meridiem: string }): string {
    const formattedHour = time.hour < 10 ? `0${time.hour}` : time.hour;
    const formattedMin = time.min < 10 ? `0${time.min}` : time.min;
    return `${formattedHour}:${formattedMin} ${time.meridiem}`;
  }

  capitalizeFirstLetter(string: string) {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  formatPhone(event: any): void {
    const input = event.target.value.replace(/\D/g, '');
    let formattedInput = '';
    if (input.length > 0) {
        formattedInput = input.substring(0, 3);
    }
    if (input.length > 3) {
        formattedInput += '-' + input.substring(3, 6);
    }
    if (input.length > 5) {
        formattedInput += '-' + input.substring(6, 10);
    }
    event.target.value = formattedInput;  
  }

  formatList(label: string, data: {count: number, values: string[]} ): string {
    if (data.count === 0) return '';
    const displayedValues = data.values.slice(0, 3).join(", ");
    const additionalCount = data.count - 3;
    return `<b>${label}:</b> ${displayedValues}${additionalCount > 0 ? ` ... and ${additionalCount} more` : ''}`;
  }
}
